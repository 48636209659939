/*
    Table of Contents

    Name                 : uena
    Author               : DexignZone
    Author Portfolio     : https://themeforest.net/user/dexignzone/portfolio
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/

@import '~angular-notifier/styles.scss';

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

////////////////////
// Abstracts
////////////////////
@import "./abstracts/abstracts";

//bootstrap library scss files
@import "./bootstrap/bootstrap";



////////////////////
// Global
////////////////////
@import "./base/base";

@import "./preloader";


////////////////////
// Components
////////////////////
@import "./layout/layout";


////////////////////
// Components
////////////////////
@import "./components/components";


////////////////////
// All pages
////////////////////
@import "./pages/pages";


////////////////////
// Version Color
////////////////////
@import "./layout/version-dark/main";

.form-group button{
    margin-top: 32px;
}
.pac-container{
    z-index: 10000!important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: $primary;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $primary;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  typeahead-container{
    max-height: 100px!important;
  }
