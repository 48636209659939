//demo styles
table#example{
    padding: 0rem 0 2rem 0;
	@include respond('phone'){
		padding: 0rem 0 1rem 0;
	}
}
table.dataTable{
    font-size: 14px;
}
#example2_wrapper {
    .dataTables_scrollBody {
        max-height: 33.25rem !important;
    }
}

#employees, #custommers {
    padding: .5rem 0 1rem 0;
}
//////////
.dataTables_wrapper .dataTables_paginate{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}



table.dataTable thead th, table.dataTable thead td, .datatable-body-cell, .datatable-header-cell{
    border-bottom: 0;
    border-top: 0;
}

table.dataTable tfoot th, table.dataTable tfoot td{
    border-top: 0;
}

table.dataTable tbody tr, table.dataTable tbody td, .datatable-body-cell{
    background: transparent !important;
}

table.dataTable thead th, .datatable-header-cell{
    color: $black;
	white-space:nowrap;
	font-size:18px;
	text-transform:capitalize;
    font-weight: 500;
	padding: 20px 15px;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
	@include respond ('laptop'){
		font-size:16px;
	}
}

table.dataTable tbody td, .datatable-body-cell{
    padding: 20px 15px;
	font-size:16px;
	@include respond('phone') {
		padding: 8px 5px;
	}
	@include respond ('laptop'){
		font-size:14px;
		padding:8px 15px;
	}
}

table.dataTable tr.selected{
    color: $primary;
}

table.dataTable tfoot th{
    color: $dark;
    font-weight: 600;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
}
/* .dataTables_wrapper .dataTables_paginate .paginate_button{
	border-radius: 12px;
    padding: 12px 20px;
	background:$primary-light;
	color:$primary;
	&:focus,
	&:active,
	&:hover{
		color: $white !important;
		background: $primary;
		border: 0 !important;
		box-shadow: none;
	}
	&.current{
		color: $white !important;
		background: $primary;
		border: 0 !important;
	}
	&.current:hover,
	&.previous:hover,
	&.next:hover{
		background: $primary;
		color: $white !important;
	}
} */


.dataTables_wrapper .dataTables_paginate{
	align-items: center;
    display: flex;
	flex-flow:wrap;
	.paginate_button.previous,.paginate_button.next{
		margin-left: 5px;
		font-size: 16px;
		margin-top: 5px;
		margin-bottom: 5px;
		border: 0!important;
		height:40px;
		width:auto;
		padding: 0 15px;
		border-radius:$radius;
		line-height:40px;
		display:inline-block;
		background-color:$primary-light;
		color:$primary!important;
		&.current:hover,
		&.previous:hover,
		&.next:hover{
			color:$white!important;
			background:$primary!important;
		}
		&.disabled{
			color:$primary!important;
		}
	}
	.paginate_button.previous{
		@include respond ('phone'){
			font-size:0px;
			&:after{
				content: "\f053";
				font-family: 'FontAwesome';
				font-size:15px;
			}
		}
	}
	.paginate_button.next{
		@include respond ('phone'){
			font-size:0px;
			&:after{
				content: "\f054";
				font-family: 'FontAwesome';
				font-size:15px;
			}
		}
	}
	span{
		.paginate_button{
			height:40px;
			width:40px;
			padding:0;
			margin-left: 5px;
			line-height:40px;
			text-align:center;
			border:0!important;
			border-radius:$radius;
			background-color:$primary-light;
			color:$primary!important;
			&.current{
				color: $white !important;
				background: $primary;
				border: 0 !important;
				&:hover{
					color:$white!important;
					background:$primary!important;
				}
			}
			&:hover,&:active{
				color:$white!important;
				background:$primary!important;
			}
		}
	}
	@include respond('phone'){
		justify-content:center;
		margin-top:0;
	}
}

.dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid #e2e2e2;
        padding: .3rem 0.5rem;
        color: rgba(113, 93, 93, 1);
        border-radius: 5px;
		width:220px;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 15px;
		.bootstrap-select{
			width: 80px!important;
			margin: 0 5px;
		}
	}
}
table.dataTable.no-footer{
    border-bottom: 0;
}


.rounded-lg{
	min-width:30px;
}
.dataTables_scroll{
    padding: 1rem 0;

    &Foot{
        padding-top: 1rem;
    }
}

.dataTablesCard{
	background-color:$white;
	border-radius:$radius;
	&.border-no{
		td{
			border-top:0!important;
		}
	}
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    @include media-breakpoint-down(md) {
        text-align: left;
    }
}
.dataTables_wrapper .dataTables_info {
    padding-top: 0.755em;
    padding-bottom: 0.755em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active{
	color: $body-color !important;
}
.dataTables_wrapper .dataTables_length, 
.dataTables_wrapper .dataTables_filter, 
.dataTables_wrapper .dataTables_info, 
.dataTables_wrapper .dataTables_processing, 
.dataTables_wrapper .dataTables_paginate {
    color: $body-color;
}
.dataTables_wrapper .dataTables_paginate .paginate_button{
	color: $body-color !important;
}



table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, .datatable-header-cell,
table.dataTable.display tbody td {
    border-color: $border-color;
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
    }
}

.dataTables_wrapper{
	.dataTables_length{
		.bootstrap-select{
			.dropdown-toggle{
				font-size: 0.813rem !important;
				padding: 0.625rem 1rem;
			}
		}
	}
}
